
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Courses = () => {


 
  return (
    <div className='Home_main_lower_div' id='Courses'>
        <div className='Home_main_lower_box'>
            <div className='Home_main_lower_box_circle'>
                <img src='./courseimg.png' alt='' />
            </div>
            <h3>General English</h3>
            <p>
            Elevate your command of the English language with our dynamic General English course. This all-encompassing program is meticulously crafted to enhance your fluency and confidence in everyday communication. You'll engage with a diverse range of engaging materials, ensuring that every lesson not only expands your grammar and vocabulary but also sharpens your listening and speaking skills. Perfect for anyone aiming to master English in a vibrant, supportive environment.

            </p>
        </div>
        <div className='Home_main_lower_box'>
            <div className='Home_main_lower_box_circle'>
            <img src='./courseimg1.png' alt='' />
            </div>
            <h3>Business English</h3>
            <p>
            Propel your career to new heights with our specialized Business English course. Designed for ambitious professionals, this program is focused on the nuances of corporate communication. From mastering eloquent business writing to delivering impactful presentations, you'll acquire the critical skills necessary to navigate and excel in the global business arena. Join us to become a more effective, articulate professional in the world of business.

            </p>
        </div>
        <div className='Home_main_lower_box'>
            <div className='Home_main_lower_box_circle'>
            <img src='./courseimg2.png' alt='' />
            </div>
            <h3>English for Kids</h3>
            <p>
            Spark your child's love for learning with our enchanting English for Kids course. Through a colorful blend of games, songs, and interactive stories, we make language learning an adventure. Tailored to young minds, this course lays a strong foundation in English, enhancing vocabulary and basic sentence construction in a joyful, nurturing environment. Watch your child's confidence and skills grow with every fun-filled lesson.

            </p>
        </div>
        <div className='Home_main_lower_box'>
            <div className='Home_main_lower_box_circle'>
            <img src='./courseimg3.png' alt='' />
            </div>
            <h3>English for Teens</h3>
            <p>
            Transform the way your teen interacts with the English language. Our English for Teens course bridges the gap between traditional learning and the interests of young adults. We combine language skills with engaging topics, fostering both fluency and a genuine love for English. Our interactive approach ensures that teens enhance their reading, writing, speaking, and listening skills in a way that's both enjoyable and relevant to their world.

            </p>
        </div>
        <div className='Home_main_lower_box'>
            <div className='Home_main_lower_box_circle'>
            <img src='./courseimg4.png' alt='' />
            </div>
            <h3>English for Adults</h3>
            <p>
                 Discover the joy of learning English at any age with our comprehensive English for Adults course. Whether you're aiming to advance in your career, travel the world, or simply embrace a personal challenge, our course is tailored to meet your unique goals. We focus on practical language use in real-life situations, ensuring that you gain not only proficiency but also the confidence to use English in your daily and professional life. Join a community of like-minded learners and start your journey to fluency today.
            </p>
        </div>

    </div>
  );
};

export default Courses;