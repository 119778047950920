

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Teacher = () => {
  const navigate = useNavigate()
  const NavigationHandler = () => {
    navigate('/Scedule-Meeting')
  }





  return (  <div className='col-md-12  teachers_main_div' id='Teacher'>
  <div className='col-md-6  p-5 teachers_left_div'>
          <img
              src='https://themewagon.github.io/known/images/author-image1.jpg'
              alt=''
          />
  </div>  
  <div className='col-md-6 teachers_right_div'>
          <Link to='/TeacherDetails' className='teachers_right_div_link' ><h3>Mark Wilson</h3></Link>
          <p>
          Meet our exceptional English teacher who brings passion and enthusiasm to the classroom every day. With a profound mastery of the English language, this educator goes beyond the traditional boundaries of teaching, creating an environment where learning becomes a joyous experience.
          Students are not only equipped with the technical aspects of English but are also inspired to develop a deep appreciation for the beauty of language.
          </p>
           <button onClick={NavigationHandler} className='btn btn-outline-success p-3 rounded-3'>Book a free trail</button>
         
  </div>
</div>
  )
}

export default Teacher