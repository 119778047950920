import React from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { async_removeuser } from '../store/actions/studentsActions'


const Dashboard = () => {
const location = useLocation()
const dispatch = useDispatch()
const logoutHandler = () => {
  dispatch(async_removeuser())
}

  return (
    <div className='col-md-12 bg-success dashboard_main_div'>
      <div className='dashboard_main_left_div'>
            <>
              <NavLink to='/dashboard' className='Dashboard_left_head_column'>
                 <h5>Speakable English Student</h5>
              </NavLink>
              <NavLink
                     to='/dashboard/profile'
                     className={`Dashboard_left_column ${
                     location.pathname === '/dashboard/profile' ? 'active' : 'inactive'
                     }`}
                     >
                     <div className='Dashboard_left_column_left_section'>
                             <i class="bi bi-house-check-fill"></i>
                     </div>
                     <div className='Dashboard_left_column_right_section'>
                            <h6>Profile</h6>
                     </div>
              </NavLink>
              <NavLink
                     to='/dashboard/Courses'
                     className={`Dashboard_left_column ${
                     location.pathname === '/dashboard/Courses' ? 'active' : 'inactive'
                     }`}
                     >
                     <div className='Dashboard_left_column_left_section'>
                      <i class="bi bi-columns-gap"></i>
                     </div>
                     <div className='Dashboard_left_column_right_section'>
                      <h6>Courses</h6>
                     </div>
              </NavLink>
              <NavLink
                     to='/dashboard/Meetings'
                     className={`Dashboard_left_column ${
                     location.pathname === '/dashboard/Meetings' ? 'active' : 'inactive'
                     }`}
                     >
                     <div className='Dashboard_left_column_left_section'>
                     <i class="bi bi-calendar3"></i>
                     </div>
                     <div className='Dashboard_left_column_right_section'>
                     <h6>Meetings</h6>
                     </div>
              </NavLink>
              <NavLink
                     to='/dashboard/Feedback'
                     className={`Dashboard_left_column ${
                     location.pathname === '/dashboard/Feedback' ? 'active' : 'inactive'
                     }`}
                     >
                     <div className='Dashboard_left_column_left_section'>
                     <i class="bi bi-database"></i>
                     </div>
                     <div className='Dashboard_left_column_right_section'>
                     <h6>Feedback</h6>
                     </div>
              </NavLink>
              <NavLink
                     to='/dashboard/Packages'
                     className={`Dashboard_left_column ${
                     location.pathname === '/dashboard/Packages' ? 'active' : 'inactive'
                     }`}
                     >
                     <div className='Dashboard_left_column_left_section'>
                      <i class="bi bi-list"></i>
                     </div>
                     <div className='Dashboard_left_column_right_section'>
                      <h6>Packages</h6>
                     </div>
              </NavLink>
              <NavLink
                     to='/dashboard/Bookings'
                     className={`Dashboard_left_column ${
                     location.pathname === '/dashboard/Bookings' ? 'active' : 'inactive'
                     }`}
                     >
                     <div className='Dashboard_left_column_left_section'>
                        <i class="bi bi-bookmarks"></i>
                     </div>
                     <div className='Dashboard_left_column_right_section'>
                        <h6>Bookings</h6>
                     </div>
              </NavLink>
              <NavLink
                     to='/dashboard/Payments'
                     className={`Dashboard_left_column ${
                     location.pathname === '/dashboard/Payments' ? 'active' : 'inactive'
                     }`}
                     >
                     <div className='Dashboard_left_column_left_section'>
                      <i class="bi bi-credit-card"></i>
                     </div>
                     <div className='Dashboard_left_column_right_section'>
                      <h6>Payments</h6>
                     </div>
              </NavLink>
              <NavLink
                     to='/dashboard/Enquirys'
                     className={`Dashboard_left_column ${
                     location.pathname === '/dashboard/Enquirys' ? 'active' : 'inactive'
                     }`}
                     >
                     <div className='Dashboard_left_column_left_section'>
                      <i class="bi bi-chat-text"></i>
                     </div>
                     <div className='Dashboard_left_column_right_section'>
                      <h6 >Enquirys</h6>
                     </div>
              </NavLink>
              <NavLink
                     to='/dashboard/setting'
                     className={`Dashboard_left_column ${
                     location.pathname === '/dashboard/setting' ? 'active' : 'inactive'
                     }`}
                     >
                     <div className='Dashboard_left_column_left_section'>
                     <i class="bi bi-gear"></i>
                     </div>
                     <div className='Dashboard_left_column_right_section'>
                     <h6 >Settings</h6>
                     </div>
              </NavLink>
           </>
           <>
              <NavLink
                     className='Dashboard_left_column'
                     onClick={logoutHandler}
                     >
                     <div className='Dashboard_left_column_left_section'>
                     <i class="bi bi-box-arrow-right"></i>
                     </div>
                     <div className='Dashboard_left_column_right_section'>
                     <h6 >Logout</h6>
                     </div>
              </NavLink>
           </>
      </div>
      <div className='dashboard_main_right_div'>
        <Outlet/>
      </div>
    </div>
  )
}

export default Dashboard