import { configureStore } from "@reduxjs/toolkit";
import  studentsSlice from './slices/studentsSlice'
import teacherSlice from './slices/teacherSlice'

export const store = configureStore({
  reducer: {
    students: studentsSlice,
    teachers: teacherSlice,
  },
});
 