import { createSlice } from "@reduxjs/toolkit";
import { fetch5Teachers,fetchTeacherDetails } from "../actions/teachersActions";

let intialState = {
  Teacherslist:[],
  TeacherDetails:[],
  loading: false,
};

const teacherSlice = createSlice({
  name: "teacher",
  initialState: intialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch5Teachers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetch5Teachers.fulfilled, (state, action) => {
        state.Teacherslist = action.payload
        state.loading = false;
      })
      .addCase(fetch5Teachers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchTeacherDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTeacherDetails.fulfilled, (state, action) => {
        state.TeacherDetails = action.payload
        state.loading = false;
      })
      .addCase(fetchTeacherDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { } = teacherSlice.actions;
export default teacherSlice.reducer;
