import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";



export const fetch5Teachers = createAsyncThunk(
    "teacher/fetch5Teachers",
    async () => {
      const response = await axios.get(`Fetch5Teachers`);
      console.log(response)
      return response.data.teacherslist
    }
  );


  

export const fetchTeacherDetails = createAsyncThunk(
    "teacher/fetchTeacherDetails",
    async (id) => {
      const response = await axios.get(`fetchTeacherDetails/${id}`);
      console.log(response)
      return response.data.teachersDetails

    }
  );