import { createSlice } from "@reduxjs/toolkit";
import { fetchAllstudents,Signup_Student,async_loaduser,async_removeuser, } from "../actions/studentsActions";

let intialState = {
  studentslist: [],
  studentDetails:[],
  loading: false,
  studentuser: null,
  isAuthenticated: false,
};

const studentsSlice = createSlice({
  name: "students",
  initialState: intialState,
  reducers: {
    loaduser: (state, action) => {
      state.studentuser = action.payload;
      state.isAuthenticated = true;
    },
    removeuser: (state, action) => {
        state.studentuser = null;
        state.isAuthenticated = false;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllstudents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllstudents.fulfilled, (state, action) => {
        state.studentslist = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllstudents.rejected, (state) => {
        state.loading = false;
      })
      .addCase(Signup_Student.pending, (state) => {
        state.loading = true;
      })
      .addCase(Signup_Student.fulfilled, (state, action) => {
        state.studentDetails = action.payload;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(Signup_Student.rejected, (state) => {
        state.loading = false;
      })  
      .addCase(async_loaduser.pending, (state) => {
        state.loading = true;
      })
      .addCase(async_loaduser.fulfilled, (state, action) => {
        state.studentDetails = action.payload;
        state.studentuser = action.payload
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(async_loaduser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(async_removeuser.pending, (state) => {
        state.loading = true;
      })
      .addCase(async_removeuser.fulfilled, (state, action) => {
        state.studentDetails = action.payload;
        state.studentuser = null
        state.isAuthenticated = false;
        state.loading = false;
      })
      .addCase(async_removeuser.rejected, (state) => {
        state.loading = false;
      });

    // Add other cases if needed
  },
});

export const { loaduser,removeuser } = studentsSlice.actions;
export default studentsSlice.reducer;
