import React, { useEffect } from 'react'
import './App.css';
import Layout from './components/Layout'
import { useDispatch } from 'react-redux';
import { async_loaduser } from './store/actions/studentsActions';
import { Route, Routes } from 'react-router-dom';
import SceduleMeeting from './components/SceduleMeeting';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './helpers/ProtectedRoute';
import Profile from './components/student-dashboard-components/Profile';
import Courses from './components/student-dashboard-components/Courses';
import Meetings from './components/student-dashboard-components/Meetings';
import Feedback from './components/student-dashboard-components/Feedback';
import Packages from './components/student-dashboard-components/Packages';
import Bookings from './components/student-dashboard-components/Bookings';
import Payments from './components/student-dashboard-components/Payments';
import Enquirys from './components/student-dashboard-components/Enquirys';
import Student_Setting from './components/student-dashboard-components/Student_Setting';
import TeacherDetails from './components/TeacherDetails';



const App = () => {

  const dispatch = useDispatch();
  // load user 
  useEffect(() => {
    dispatch(async_loaduser());
  });
 
  return <>
  
  {/* ---------------------------------------------------------------------------------------------------- */}
  
  <Routes>
      <Route 
        path='/'
        element={<Layout/>}
      />
      <Route   
          path ='/Scedule-Meeting'
          element={<SceduleMeeting/>}
      />
      <Route
          path='/TeacherDetails/:TeacherID'
          element={<TeacherDetails/>}
      
      />



      
            {/* Dashboard Routes */}
            <Route  
                  path ='/dashboard'
                  element={
                    <ProtectedRoute>
                        <Dashboard/>
                    </ProtectedRoute>
                  }
                  >
                  <Route
                      path='/dashboard/profile'
                      element={<Profile/>}
                  />
                   <Route
                      path='/dashboard/Courses'
                     element={<Courses/>}
                  /> 
                   <Route
                      path='/dashboard/Meetings'
                      element={<Meetings/>}
                  /> 
                   <Route
                      path='/dashboard/Feedback'
                      element={<Feedback/>}
                  /> 
                   <Route
                      path='/dashboard/Packages'
                      element={<Packages/>}
                  /> 
                   <Route
                      path='/dashboard/Bookings'
                      element={<Bookings/>}
                  /> 
                   <Route
                      path='/dashboard/Payments'
                      element={<Payments/>}
                  /> 
                   <Route
                      path='/dashboard/Enquirys'
                      element={<Enquirys/>}
                  /> 
                   <Route
                      path='/dashboard/setting'
                      element={<Student_Setting/>}
                  />     
            </Route>
    </Routes>
    
  </> 


}

export default App